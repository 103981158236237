import { View } from "@/ui";
import { Colors } from "@/constants";
import { getLangDir, getLocale } from "@/models/utils";
import { NextSeo } from "next-seo";
import { useTranslation } from "react-i18next";
import LandingPage from "@/ui/LandingPage";

function Home() {
  const { t } = useTranslation();
  const locale = getLocale();

  const titleByLocale = {
    en: "1Transcribe - Transcribe audio to text",
    fr: "1Transcribe - Transcrire audio en texte",
    es: "1Transcribe - Transcribir audio a texto",
    pt: "1Transcribe - Transcrever audio em texto",
  };

  let title = titleByLocale[locale as keyof typeof titleByLocale];

  if (!title) {
    title = t("seo.title");
  }

  return (
    <>
      <NextSeo title={title} description={t("seo.description")} />
      <View
        style={{
          direction: getLangDir(),
          flex: 1,
          backgroundColor: Colors.white,
          zIndex: 1,
        }}
      >
        <LandingPage />
      </View>
    </>
  );
}

export default Home;
